<!--
 * @Author: chenxing
 * @Date: 2021-05-17 15:54:23
 * @LastEditors: Yran
 * @LastEditTime: 2021-11-18 10:42:44
-->
<template>
  <div class="role_system w100 h100">
    <a-row class="w100 filter_row">
      <a-col :span="6">
        <a-form-item :wrapper-col="{ span: 24 }" style="width: 90%">
          <a-input v-model:value="filterData.roleName" placeholder="角色名称" allow-clear @change="roleNameChange" @press-enter="selectRoleClickBtn" />
        </a-form-item>
      </a-col>
      <a-col :span="6">
        <a-space>
          <a-button type="primary" @click="selectRoleClickBtn" :disabled="tableLoading">搜索</a-button>
          <a-button v-hasPermission="'ROLE_ADD'" type="primary" @click="addRoleClickBtn" :disabled="tableLoading">
            新增
            <template #icon>
              <PlusOutlined />
            </template>
          </a-button>
          <a-button v-hasPermission="'ROLE_DELETE'" type="primary" @click="removeRoleClickBtn" :disabled="selectedRowKeys.length == 0">
            删除
            <template #icon>
              <DeleteOutlined />
            </template>
          </a-button>
        </a-space>
      </a-col>
    </a-row>

    <div class="table_wrapper w100 bcg_w">
      <a-table :scroll="{ y: 300 }" size="small" :data-source="roleDataSource" :columns="roleColumns" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :custom-row="record => customClickRow(record)" row-key="roleId" :pagination="paginationTable" @change="onTableChange" :loading="tableLoading" bordered>
        <template #operation="{ record }">
          <a-space v-hasPermission="'ROLE_EDIT'">
            <a @click="updateRoleClickBtn(record)"> 编辑 </a>
          </a-space>
        </template>
      </a-table>
    </div>

    <!-- <a-modal v-model:visible="editRoleVisible" :title="editRoleModalType" width="27.3rem" @ok="confirmRoleModalClickBtn" @cancel="cancelRoleModalClickBtn" cancel-text="取消" ok-text="确认" :mask-closable="false" destroy-on-close>
      <a-form ref="roleModalForm" :model="roleModalData" :rules="roleModalRules">
        <a-form-item :wrapper-col="{ span: 18 }" :label-col="{ span: 4 }" label="角色名称" name="roleName">
          <a-input v-model:value="roleModalData.roleName" placeholder="角色名称" @blur="inputBlur('角色名称', 'roleModalData', 'roleName')" />
        </a-form-item>
        <a-form-item label="授权菜单" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-transfer
            :data-source="accessList"
            show-search
            :list-style="{
              width: '7.57rem',
              height: '9.09rem',
            }"
            :locale="{ itemUnit: '项', itemsUnit: '项', notFoundContent: '列表为空', searchPlaceholder: '请输入搜索内容' }"
            :operations="['添加授权', '取消授权']"
            :target-keys="roleModalData.menuIdList"
            :render="item => `${item.key}-${item.title}`"
            @change="handleChange"
          >
          </a-transfer>
        </a-form-item>
        <a-form-item label="备注" :label-col="{ span: 4 }" :wrapper-col="{ span: 18 }">
          <a-textarea v-model:value="roleModalData.remark" placeholder="请输入备注" :rows="4" :maxlength="200" />
        </a-form-item>
      </a-form>
    </a-modal> -->
    <a-modal v-model:visible="deleteModalvisible" title="信息" @ok="deleteRoleClickBtn" cancel-text="取消" ok-text="确认" :width="400" :mask-closable="false">
      <p style="display: flex; align-items: center">
        <ExclamationCircleOutlined style="color: #fcc760; font-size: 0.7rem; margin-left: 0.1rem"></ExclamationCircleOutlined><span style="margin-left: 0.3rem">{{ deleteModalType }}</span>
      </p>
    </a-modal>
    <a-modal v-model:visible="setRoleAuthorityVisible" class="set-role-authority-modal" :width="788" title="角色权限设置" :mask-closable="false" centered @cancel="onSetRoleModalClose">
      <a-form ref="roleModalForm" :model="roleAuthorityModalData" :rules="roleModalRules">
        <a-row class="info-box">
          <a-form-item label="角色名称" class="mb0 mr12" name="roleName">
            <a-input v-model:value="roleAuthorityModalData.roleName" placeholder="请输入角色名称" style="height: 32px"></a-input>
          </a-form-item>
          <a-form-item class="mb0" name="deptIdList">
            <SelectMultiple v-model:value="roleAuthorityModalData.deptIdList" :select-data="roleAuthorityModalData.deptIdList" :select-options="departmentData" :allow-clear="true" mode="multiple" placeholder="可适用部门" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="2" :show-arrow="true" select-option-value="deptId" select-option-label="name" style="min-width: 216px" />
          </a-form-item>
        </a-row>
      </a-form>
      <div class="authority-box">
        <a-tabs v-model:activeKey="roleAuthorityActiveKey" @change="roleAuthorityChange">
          <a-tab-pane key="func" tab="功能权限"></a-tab-pane>
          <a-tab-pane key="data" tab="数据权限"></a-tab-pane>
        </a-tabs>

        <a-spin :spinning="spinning">
          <div class="authority-list flexJustifyBetween">
            <a-menu id="menuLists" class="menu-list" v-model:selectedKeys="selectedKeys" v-model:openKeys="openKeys" mode="inline">
              <a-menu-item class="flexBetweenCenter" v-for="menu in menuTabList" :key="menu.menuId">
                <a-checkbox :checked="determinCheckAll(menu)" :indeterminate="determinIndeterminate(menu)" @change="e => checkedAllChagne(e, menu)"> </a-checkbox>
                <a class="color85">{{ menu.name }}</a>

                <PlusCircleOutlined v-show="roleAuthorityActiveKey === 'func'" @click.stop="addFunc(menu)" />
              </a-menu-item>
            </a-menu>
            <div class="menu-detail-list">
              <div class="menu-detail-item">
                <a-card v-for="menuChild in getMenuTabList" :key="menuChild.menuId" :id="menuChild.menuId" :bordered="false" style="100%">
                  <template #title>
                    <a-checkbox
                      :disabled="menuChild.disabled"
                      :checked="determinCheckAll(menuChild)"
                      :indeterminate="determinIndeterminate(menuChild)"
                      @change="
                        e => {
                          checkedAllChagne(e, menuChild);
                        }
                      "
                    >
                    </a-checkbox>
                    <span class="font14 weight cursorPoi pl10">{{ menuChild.meta ? menuChild.meta.title : menuChild.name }}</span>
                    <span style="color: #666; font-size: 0.375rem">{{ ` (已选${getCheckedLength(menuChild.list) || 0})` }}</span>
                  </template>
                  <!-- 二级目录 -->
                  <div v-if="menuChild.funcPointList || menuChild?.list[0]?.level == 3">
                    <a-row class="checkbox-wrapper">
                      <a-col :span="8" v-show="!info.hide" v-for="info in menuChild.list" :key="info.menuId">
                        <a-checkbox
                          class="checkbox-item"
                          v-model:checked="info.checked"
                          :disabled="info.disabled"
                          @change="
                            e => {
                              checkedChagne(e, info);
                            }
                          "
                        >
                          <a-tooltip>
                            <template #title>
                              {{ info.name }}
                            </template>
                            <span class="check-label flexJustifyBetween">
                              <span class="textHide check-label-name">{{ info.name }}</span>
                              <span class="flexBetweenCenter toolbox"><FormOutlined class="mr5" @click.stop="editFunc(info)" /><DeleteOutlined @click.stop="delectFunc(info)" /> </span>
                            </span>
                          </a-tooltip>
                        </a-checkbox>
                        <SelectMultiple class="checkbox-select" placeholder="选择部门" v-show="info.checked && roleAuthorityActiveKey === 'data' && info.dataCode == 'DEPT'" v-model:value="info.permissionJson" :select-data="info.permissionJson" :select-options="departmentData" :allow-clear="true" mode="multiple" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="deptId" select-option-label="name" />
                        <SelectMultiple class="checkbox-select" placeholder="选择渠道" v-show="info.checked && roleAuthorityActiveKey === 'data' && info.dataCode == 'CHANNEL'" v-model:value="info.permissionJson" :select-data="info.permissionJson" :select-options="channelList" :allow-clear="true" mode="multiple" option-filter-prop="label" option-label-prop="label" :filter-option="true" :max-tag-count="1" :show-arrow="true" select-option-value="value" select-option-label="label" />
                      </a-col>
                    </a-row>
                  </div>
                  <!-- 三级目录 -->
                  <div v-else>
                    <a-card class="child-card" v-for="childMenu in menuChild.list" :key="childMenu.menuId" :id="childMenu.menuId" :bordered="false" style="100%">
                      <template #title>
                        <div class="flexJustifyBetween">
                          <div>
                            <a-checkbox
                              :disabled="childMenu.disabled"
                              :checked="determinCheckAll(childMenu)"
                              :indeterminate="determinIndeterminate(childMenu)"
                              @change="
                                e => {
                                  checkedAllChagne(e, childMenu);
                                }
                              "
                            >
                            </a-checkbox>
                            <span class="child-menu-name font14 weight cursorPoi pl10">{{ childMenu.meta ? childMenu.meta.title : childMenu.name }}</span>
                          </div>
                        </div>
                      </template>
                      <a-row class="checkbox-wrapper">
                        <a-col :span="8" v-show="!info.hide" v-for="info in childMenu.list" :key="info.menuId">
                          <a-checkbox
                            class="checkbox-item"
                            v-model:checked="info.checked"
                            :disabled="info.disabled"
                            @change="
                              e => {
                                checkedChagne(e, info);
                              }
                            "
                          >
                            <a-tooltip>
                              <template #title>
                                {{ info.name }}
                              </template>
                              <span class="check-label flexJustifyBetween">
                                <span class="textHide check-label-name">{{ info.name }}</span>
                                <span class="flexBetweenCenter toolbox"><FormOutlined class="mr5" @click.stop="editFunc(info)" /><DeleteOutlined @click.stop="delectFunc(info)" /> </span>
                              </span>
                            </a-tooltip>
                          </a-checkbox>
                        </a-col>
                      </a-row>
                    </a-card>
                  </div>
                </a-card>
              </div>
            </div>
          </div>
        </a-spin>
      </div>
      <template #footer>
        <div class="buttons">
          <a-button class="btn close-btn" @click="onSetRoleModalClose">取消</a-button>
          <a-button class="btn submit-btn" type="primary" :loading="btnLoading" @click="onSetRoleModalSubmit">确定</a-button>
        </div>
      </template>
    </a-modal>
    <a-modal v-model:visible="addFuncVisible" class="add-func-modal" :width="586" title="添加功能" :mask-closable="false" centered :mask="true" :mask-style="{ zIndex: 1001 }" @cancel="onAddFuncModalClose">
      <a-form ref="addFuncFormRef" :model="roleAuthorityData" :rules="addFuncFormRefRules">
        <a-row>
          <a-form-item label="菜单选择" class="mb0" name="parentId">
            <SelectMultiple v-model:value="roleAuthorityData.parentId" :is-tree="true" :select-data="roleAuthorityData.parentId" :replace-fields="{ children: 'list', title: 'name', key: 'menuId', value: 'menuId' }" :tree-data="menuAddFuncData.list" :allow-clear="true" placeholder="请选择菜单层级" tree-node-filter-prop="title" tree-node-label-prop="title" :max-tag-count="2" style="min-width: 216px" />
          </a-form-item>
        </a-row>
        <a-row>
          <a-form-item label="权限功能" class="mb0"> <PlusCircleOutlined v-if="!roleAuthorityData.menuId" class="cursorPoi" :style="{ color: '#2F70F4' }" @click.stop="addAuthorityFunc(menu)" /></a-form-item>
          <a-col v-for="(auth, index) in roleAuthorityData.authorityList" :key="index" :span="24" :offset="3">
            <a-form class="flexJustifyStart" ref="addFuncItemRef" :model="auth" :rules="addFuncItemRules">
              <a-form-item class="mb0" name="name">
                <a-input v-model:value="auth.name" placeholder="请输入功能名称" style="height: 32px; width: 200px; margin-right: 12px"></a-input>
              </a-form-item>
              <a-form-item class="mb0" name="paramKey">
                <a-input v-model:value="auth.paramKey" placeholder="请输入key值" style="height: 32px; width: 200px; margin-right: 12px"></a-input>
                <DeleteOutlined class="cursorPoi" v-show="auth.deletable" :style="{ fontSize: '14px' }" @click="delectAuthority(index)" />
              </a-form-item>
            </a-form>
          </a-col>
        </a-row>
      </a-form>
      <template #footer>
        <div class="buttons">
          <a-button class="btn close-btn" @click="onAddFuncModalClose">取消</a-button>
          <a-button class="btn submit-btn" type="primary" :loading="btnLoading" @click="onAddFuncModalSubmit">确定</a-button>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { channelList } from '@/config/globalData.js';
import SelectMultiple from '@/components/selectMultiple/index.vue';
import _ from 'lodash';
import { getDepartmentData } from '@/api/org/account.js';
import { PlusOutlined, DeleteOutlined, ExclamationCircleOutlined, PlusCircleOutlined, FormOutlined } from '@ant-design/icons-vue';
import zhCN from 'ant-design-vue/es/locale/zh_CN';
import { getRoleListData, saveRoleData, updateRoleData, deleteRoleData, getRoleData, getRoleDataByRoleId } from '@/api/systemManage/roleManage.js';
import { getMenuList, saveMenus, updateMenu, deleteMenu } from '@/api/systemManage/menuManage.js';
import { getUserInfo } from '@/api/base/user';
export default {
  data() {
    const newItem = {
      name: '',
      paramKey: '',
      type: 2,
    };

    return {
      btnLoading: false, // 确定按钮loading
      addFuncFormRefRules: {
        parentId: [{ required: true, message: '请选择菜单层级', trigger: 'blur' }],
      },
      addFuncItemRules: {
        name: [{ required: true, message: '请输入功能名称', trigger: 'blur' }],
        paramKey: [{ required: true, message: '请输入key值', trigger: 'blur' }],
      },
      spinning: false,
      // 新增菜单层级列表
      menuAddFuncData: [],
      // 媒体渠道列表
      channelList,
      // 角色权限设置弹窗数据
      roleAuthorityModalData: {},
      // 新增功能数据
      roleAuthorityData: {
        authorityList: [{ ...newItem }],
      },
      // 新增item
      newItem,
      // 添加功能权限弹窗
      addFuncVisible: false,
      // 折叠的key数组
      activeKeyList: [],
      // 已选择数据
      checkedList: {},
      // 全选项数组
      checkAllList: [],
      // 选择但非全选项数组
      indeterminateList: [],
      menuList: [], // 菜单列表数据
      menuSourceList: [], // 菜单列表源数据
      menuRoleData: [], // 菜单数据权限数据
      menuSourceRoleData: [], // 菜单数据权限源数据
      menuTabList: [], // 菜单列表tab数据
      menuChildList: [], // 菜单子级列表
      selectedKeys: [], // 权限菜单选中列表
      openKeys: [], // 权限菜单展开列表
      roleAuthorityActiveKey: 'func', // tab选中
      departmentData: [], // 部门列表
      setRoleAuthorityVisible: false, // 角色权限设置弹窗
      tableLoading: false, //表格加载
      editRoleModalType: '编辑角色',
      editRoleVisible: false,
      deleteModalType: '请选择一条记录',
      deleteModalvisible: false,
      locale: zhCN,
      isSelectRole: false, //是否是查询
      filterData: {}, //筛选数据对象
      roleColumns: [
        {
          title: '角色ID',
          dataIndex: 'roleId',
          key: 'roleId',
          width: '4rem',
        },
        {
          title: '角色名称',
          dataIndex: 'roleName',
          key: 'roleName',
          width: '10rem',
        },
        // {
        //   title: '所属部门',
        //   dataIndex: 'deptName',
        //   key: 'deptName',
        // },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          width: '8rem',
        },
        {
          title: '操作',
          dataIndex: 'operation',
          slots: {
            customRender: 'operation',
          },
        },
      ],
      roleModalDeptId: [
        { name: '部门1', id: 222 },
        { name: '部门2', id: 333 },
      ],
      roleDataSource: [],
      selectedRowKeys: [], // 表单的选中的行
      roleModalData: {}, //编辑新增角色会话框对象
      roleModalRules: {
        roleName: [
          {
            required: true,
            message: '请输入角色名称',
            trigger: 'blur',
          },
          {
            validator: function validateRoleName(rule, value) {
              if (!value) {
                return Promise.reject('请输入角色名称');
              } else if (value.indexOf(' ') === 0 || value.lastIndexOf(' ') === value.length - 1) {
                return Promise.reject('请不要在角色名称前后输入空格');
              } else {
                return Promise.resolve();
              }
            },
            trigger: 'blur',
            required: true,
          },
        ],
        deptIdList: [
          {
            required: true,
            message: '请选择可适用部门',
            trigger: 'blur',
            type: 'array',
          },
        ],
      },
      paginationTable: {
        limit: '30',
        page: '1',
        pageSize: 30,
        showTotal: total => `总计${total}条数据`,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '30', '50'],
      },
      // 菜单列表
      accessList: [],
    };
  },
  components: {
    PlusOutlined,
    DeleteOutlined,
    ExclamationCircleOutlined,
    PlusCircleOutlined,
    FormOutlined,
    SelectMultiple,
  },
  created() {
    this.getRoleList();
    this.getMenuList();
    this.getDepartment();
    this.getRoleData();
  },
  watch: {
    menuTabList(newVal) {
      if (newVal && newVal.length > 0) {
        this.selectedKeys = [this.menuTabList[0].menuId];
      }
    },
  },
  mounted() {
    this.menuSourceList = _.cloneDeep(this.sourceList);
    this.menuList = _.cloneDeep(this.menuSourceList);
    this.NestedIteratorFuncPoint(this.menuList);
    this.menuTabList = this.menuList;
    this.selectedKeys = [this.menuList[0]?.menuId];
  },
  computed: {
    ...mapState({
      sourceList: state => state.oauth.userInfo.menuList,
    }),
    getMenuTabList() {
      const list = this.menuTabList.find(item => {
        return item.menuId == this.selectedKeys[0];
      })?.list;
      return list;
    },
  },
  methods: {
    /** 删除新增功能权限
     * @param {*}
     * @return {*}
     */
    delectAuthority(index) {
      this.roleAuthorityData.authorityList?.splice(index, 1);
    },
    /**新增功能弹窗确定
     * @param {*}
     * @return {*}
     */
    async onAddFuncModalSubmit() {
      let postData = [];
      this.btnLoading = true;
      await this.$refs.addFuncFormRef.validate().then(async () => {
        await this.$refs.addFuncItemRef.validate().then(() => {
          if (!this.roleAuthorityData.menuId) {
            this.roleAuthorityData.authorityList?.forEach(item => {
              postData.push({ parentId: this.roleAuthorityData.parentId, ...item });
            });
            this.spinning = true;
            saveMenus(postData).then(res => {
              if (res.code === 0) {
                this.$message.success('新增功能成功！');
                this.getUserInfo();
              }
            });
          } else {
            postData = { parentId: this.roleAuthorityData.parentId, menuId: this.roleAuthorityData.menuId, ...this.roleAuthorityData.authorityList[0], type: 2 };
            this.spinning = true;
            updateMenu(postData).then(res => {
              if (res.code === 0) {
                this.$message.success('编辑菜单成功！');
                this.getUserInfo();
              }
            });
          }
          this.btnLoading = false;
        });
      });
      this.onAddFuncModalClose();
    },
    /** 新增功能弹窗关闭
     * @param {*}
     * @return {*}
     */
    onAddFuncModalClose() {
      this.$refs.addFuncFormRef?.resetFields();
      this.$refs.addFuncItemRef?.resetFields();
      this.roleAuthorityData = {
        authorityList: [{ ...this.newItem }],
      };
      this.addFuncVisible = false;
    },
    /** 编辑功能
     * @param {*}
     * @return {*}
     */
    async editFunc(menu) {
      this.roleAuthorityData = {
        parentId: menu.parentId,
        menuId: menu.menuId,
        authorityList: [{ name: menu.name, paramKey: menu.paramKey }],
      };
      const NestedIterator = (nestedList, id) => {
        let vals = [];
        const dfs = nestedList => {
          for (const nest of nestedList) {
            if (nest.menuId == id) {
              vals.push(nest);
            } else if (nest.list) {
              dfs(nest.list);
            }
          }
        };
        dfs(nestedList);
        return vals;
      };
      const parentMenu = NestedIterator(this.menuSourceList, menu.parentId)[0];
      if (parentMenu) {
        this.menuAddFuncData = NestedIterator(this.menuSourceList, parentMenu.parentId)[0];
      } else {
        this.menuAddFuncData = [];
      }
      this.addFuncVisible = true;
    },
    /** 删除功能
     * @param {*}
     * @return {*}
     */
    async delectFunc(menu) {
      this.spinning = true;
      let res = await deleteMenu({ menuIdList: [menu.menuId] });
      if (res.code === 0) {
        this.$message.success('删除菜单成功！');
        this.onAddFuncModalClose();
        this.deleteModalVisible = false;
        this.getUserInfo();
      }
    },
    /** 获取菜单列表
     * @param {*}
     * @return {*}
     */
    async getUserInfo() {
      let res = await getUserInfo();
      const _this = this;
      const NestedIterator = nestedList => {
        const dfs = nestedList => {
          for (const nest of nestedList) {
            nest.meta = nest.meta ? nest.meta : {};
            nest.meta.title = nest.name;
            if (nest.list) {
              dfs(nest.list);
            }
          }
        };
        dfs(nestedList);
      };
      NestedIterator(res.userInfo.menuList);
      this.menuSourceList = _.cloneDeep(res.userInfo.menuList);
      _this.menuList = _.cloneDeep(this.menuSourceList);
      this.NestedIteratorFuncPoint(this.menuList);
      _this.menuTabList = [];
      _this.menuTabList = _this.menuList;
      this.spinning = false;
    },
    /** 角色权限设置tab切换
     * @param {*} activeKey
     * @return {*}
     */
    roleAuthorityChange(activeKey) {
      this.menuTabList = activeKey == 'func' ? this.menuList : this.menuRoleData;
    },
    /** 获取所有数据权限数据
     * @param {*}
     * @return {*}
     */
    async getRoleData() {
      this.spinning = true;
      let res = await getRoleData();
      const NestedIterator = nestedList => {
        const dfs = nestedList => {
          for (const nest of nestedList) {
            nest.menuId = nest.id;
            nest.name = nest.dataPermissionName;
            if (nest.list) {
              dfs(nest.list);
            }
          }
        };
        dfs(nestedList);
      };
      NestedIterator(res.list);
      this.menuRoleData = res.list;
      this.menuSourceRoleData = _.cloneDeep(this.menuRoleData);
      this.spinning = false;
    },
    /** 新增角色事件
     * @param {*}
     * @return {*}
     */
    addRoleClickBtn() {
      this.spinning = true;
      this.menuList = _.cloneDeep(this.menuSourceList);
      this.NestedIteratorFuncPoint(this.menuList);
      this.menuTabList = this.menuList;
      this.setRoleAuthorityVisible = true;
      this.roleAuthorityModalData = {};
      this.spinning = false;
      // this.$nextTick(() => {
      //   document.getElementsByClassName('menu-detail-list')[0].addEventListener('scroll', this.scroll);
      // });
    },

    // scroll() {
    //   // setTimeout(() => {
    //   let titleItemList = document.querySelectorAll('.menu-detail-item');
    //   let arr = Array.from(titleItemList).reverse();
    //   arr = arr.find(item => {
    //     if (item.getBoundingClientRect().top <= 190) {
    //     }
    //     return item.getBoundingClientRect().top <= 190;
    //   });
    //   this.selectedKeys = [Number(arr?.id || titleItemList[0]?.id)];
    //   // }, 0);
    // },
    /** 修改角色事件
     * @param {Object} record 角色的信息
     * @return {*}
     */
    async updateRoleClickBtn(record) {
      this.spinning = true;
      const _this = this;
      this.menuList = _.cloneDeep(this.menuSourceList);
      this.NestedIteratorFuncPoint(this.menuList);
      if (record) {
        record.menuIdList = record.menuIdList ? record.menuIdList : [];
        record.deptIdList = record.deptIdList ? record.deptIdList : [];
        _this.setRoleAuthorityVisible = true;
        _this.roleAuthorityModalData = {
          ...record,
        };
        let res = await getRoleDataByRoleId(record.roleId);
        res.list?.forEach(item => {
          _this.NestedIteratorRoleDataChecked(_this.menuRoleData, item);
        });
        _this.roleAuthorityModalData.menuIdList.forEach(item => {
          _this.NestedIteratorChecked(_this.menuList, item);
        });
        this.menuTabList = this.menuList;
      }
      this.spinning = false;
    },
    /** 角色权限设置弹窗关闭
     * @param {*}
     * @return {*}
     */
    onSetRoleModalClose() {
      this.roleAuthorityModalData = {};
      this.menuRoleData = _.cloneDeep(this.menuSourceRoleData);
      this.menuList = _.cloneDeep(this.menuSourceList);
      this.menuTabList = this.menuList;
      this.roleAuthorityActiveKey = 'func';
      this.NestedIteratorFuncPoint(this.menuList);
      this.setRoleAuthorityVisible = false;
    },
    /**角色权限设置弹窗确定
     * @param {*}
     * @return {*}
     */
    onSetRoleModalSubmit() {
      this.btnLoading = true;
      let menuIdList = [];
      let sysRoleDataList = [];

      this.spinning = true;
      this.$refs.roleModalForm
        .validate()
        .then(() => {
          this.NestedIterator(this.menuList)?.forEach(item => {
            if (item.checked) menuIdList.push(item.menuId);
          });
          this.NestedIteratorRoleData(this.menuRoleData)?.forEach(item => {
            if (item.checked) {
              sysRoleDataList.push({ sysDataPermissionId: item.menuId, permissionJson: JSON.stringify(item.permissionJson) });
            }
          });
          const postData = {
            ...this.roleAuthorityModalData,
            menuIdList: menuIdList,
            sysRoleDataList: sysRoleDataList,
          };
          if (postData.roleId) {
            updateRoleData(postData).then(res => {
              if (res.code === 0) {
                this.$message.success('修改成功');
                this.onSetRoleModalClose();
                this.paginationTable.page = '1';
                this.getRoleList();
                this.spinning = false;
              }
              this.btnLoading = false;
            });
          } else {
            saveRoleData(postData).then(res => {
              if (res.code === 0) {
                this.$message.success('新增成功');
                this.onSetRoleModalClose();
                this.paginationTable.page = '1';
                this.getRoleList();
                this.spinning = false;
              }
              this.btnLoading = false;
            });
          }
        })
        .catch(err => {
          this.spinning = false;
          this.btnLoading = false;
        });
    },
    /** 深度优先搜索扁平化数组
     * @param {*} nestedList
     * @return {*}
     */
    NestedIterator(nestedList) {
      let vals = [];
      const dfs = nestedList => {
        for (const nest of nestedList) {
          vals.push(nest);
          if (nest.list) dfs(nest.list);
        }
      };
      dfs(nestedList);
      return vals;
    },
    /**深度优先搜索扁平化数据权限数组
     * @param {*} nestedList
     * @return {*}
     */
    NestedIteratorRoleData(nestedList) {
      let vals = [];
      const dfs = nestedList => {
        for (const nest of nestedList) {
          if (!nest.list || nest.list.length > 0) {
            dfs(nest.list);
          } else {
            vals.push(nest);
          }
        }
      };
      dfs(nestedList);
      return vals;
    },
    /** 格式化FuncpointList数组
     * @param {*} nestedList
     * @return {*}
     */
    NestedIteratorFuncPoint(nestedList) {
      const dfs = nestedList => {
        for (const nest of nestedList) {
          if (!nest.funcPointList) {
            dfs(nest.list);
          } else {
            nest.list = _.cloneDeep(nest.funcPointList);
          }
        }
      };
      dfs(nestedList);
    },
    /** 设置已选中
     * @param {*} nestedList
     * @return {*}
     */
    NestedIteratorChecked(nestedList, menuId) {
      const dfs = nestedList => {
        for (const nest of nestedList) {
          if (nest.menuId != menuId) {
            if (nest.list?.length > 0) dfs(nest.list);
          } else {
            nest.checked = true;
            nest.checkedAll = true;
          }
        }
      };
      dfs(nestedList);
    },
    /** 设置数据权限已选中
     * @param {*} nestedList
     * @return {*}
     */
    NestedIteratorRoleDataChecked(nestedList, item) {
      const dfs = nestedList => {
        for (const nest of nestedList) {
          if (nest.menuId != item.id) {
            if (nest.list?.length > 0) dfs(nest.list);
          } else {
            nest.checked = true;
            nest.checkedAll = true;
            nest.permissionJson = item.permissionJson ? JSON.parse(item.permissionJson) : [];
          }
        }
      };
      dfs(nestedList);
    },
    /** 新增权限功能item
     * @param {*}
     * @return {*}
     */
    addAuthorityFunc() {
      this.roleAuthorityData.authorityList.push({ ...this.newItem, deletable: true });
    },
    /** 打开添加功能弹窗
     * @param {*} menu
     * @return {*}
     */
    addFunc(menu) {
      this.menuAddFuncData = this.menuSourceList.filter(item => {
        return item.menuId == menu.menuId;
      })[0];
      this.addFuncVisible = true;
    },
    /** 获取选中的个数
     * @param {*} menu
     * @return {*}
     */
    getCheckedLength(menu) {
      return _.countBy(menu, item => {
        return item.checked ? 'checkedLength' : 'unCheckedLength';
      }).checkedLength;
    },
    /** 单选框变化
     * @param {*} e
     * @param {*} menu
     * @return {*}
     */
    checkedChagne(e, menu) {
      menu.checked = e.target.checked;
      menu.checkedAll = e.target.checked;
    },
    /** 全选框变化
     * @param {*} e
     * @param {*} menu
     * @return {*}
     */
    checkedAllChagne(e, menu) {
      const NestedIterator = nestedList => {
        const dfs = nestedList => {
          for (const nest of nestedList) {
            nest.checked = e.target.checked;
            nest.checkedAll = e.target.checked;
            if (nest.list) {
              dfs(nest.list);
            }
          }
        };
        dfs(nestedList);
      };
      menu.checked = e.target.checked;
      menu.checkedAll = e.target.checked;
      if (menu.list?.length > 0) NestedIterator(menu.list);
    },
    /** 是否全选
     * @param {*} subItems
     * @return {*}
     */
    determinCheckAll(subItems) {
      let result;
      if (subItems.list?.length > 0) {
        result = subItems.list.every(item => item.checkedAll);
      } else if (subItems.list?.length === 0 && subItems.checkedAll) {
        result = true;
      } else {
        result = false;
      }
      return result;
    },
    /** 是否半选
     * @param {*} subItems
     * @return {*}
     */
    determinIndeterminate(subItems) {
      let result;
      if (subItems.list?.length > 0) {
        result = subItems.list.some(item => item.checked) && !subItems.list.every(item => item.checkedAll);
      } else if (subItems.list?.length === 0 && subItems.checked) {
        result = false;
      } else {
        result = false;
      }
      subItems.checked = subItems.list?.some(item => item.checked) || (subItems.list?.length === 0 && subItems.checked);
      subItems.checkedAll = (subItems.list?.length > 0 && subItems.list?.every(item => item.checked)) || (subItems.list?.length === 0 && subItems.checked);
      return result;
    },

    /** 复选框是否选中
     * @param {*} value
     * @return {*}
     */
    getChecked(value) {
      return (
        this.checkAllList.findIndex(item => {
          return item == value;
        }) > -1
      );
    },
    /** 判断当前索引是否在折叠数组中
     * @param {Number} index 索引
     * @return {*}
     */
    getCollapse(index) {
      return this.activeKeyList.indexOf(index) !== -1;
    },
    /** 获取部门列表
     * @param {*}
     * @return {*}
     */
    getDepartment() {
      getDepartmentData().then(res => {
        if (res.code === 0) {
          this.departmentData = res.deptList;
        }
      });
    },
    /** 输入框失去焦点校验前后空格
     * @param {String} inputName 输入框的label
     * @param {String} obj 输入框的值所属对象, 如果非对象中的字段请传递null
     * @param {String} val 输入框的值对应字段
     * @return {*}
     */
    inputBlur(inputName, obj, val) {
      let value = obj ? this[obj][val] : this[val];
      if (value && (value.indexOf(' ') === 0 || value.lastIndexOf(' ') === value.length - 1)) {
        this.$message.warning(`请不要在${inputName}前后输入空格！`);
        obj ? (this[obj][val] = this[obj][val].trim()) : (this[val] = this[val].trim());
      }
    },
    /** 获取菜单列表
     * @param {*}
     * @return {*}
     */
    async getMenuList() {
      let res = await getMenuList({ page: '1', limit: '1000' });
      let arrList = res.code === 0 && res.page?.list;
      arrList?.forEach(item => {
        item.key = item.menuId.toString();
        item.title = item.name;
        item.description = item.name;
      });
      this.accessList = arrList;
    },
    /** 授权移动
     * @param {Array} keys 移动后右边的数据
     * @param {String} direction 移动方向
     * @param {Array} moveKeys 移动的数据
     * @return {*}
     */
    handleChange(keys, direction, moveKeys) {
      this.roleModalData.menuIdList = keys;
    },
    roleNameChange() {
      if (!this.filterData.roleName) {
        this.isSelectRole = false;
        this.paginationTable.page = '1';
        this.getRoleList();
      } else {
        this.filterData.roleName = this.filterData.roleName.trim();
      }
    },
    onTableChange(pagination) {
      this.paginationTable.page = pagination.current.toString();
      this.paginationTable.pageSize = pagination.pageSize;
      this.paginationTable.limit = pagination.pageSize.toString();
      // 查询分页
      if (this.isSelectRole) {
        this.getSelectRoleNameList();
      } else {
        // 默认分页
        this.getRoleList();
      }
    },
    /**
     * @param {*} getRoleList 获取所有角色数据
     * @return {*}
     */
    async getRoleList() {
      this.tableLoading = true;
      let page = this.paginationTable.page;
      let limit = this.paginationTable.limit;
      let roleName = '';
      let res = await getRoleListData(roleName, limit, page);
      this.roleDataSource = res.code === 0 && res.page.list;
      this.paginationTable.total = res.code === 0 && res.page.totalCount;
      this.paginationTable.limit = res.code === 0 && res.page.pageSize;
      this.paginationTable.current = res.code === 0 && res.page.currPage;
      this.tableLoading = false;
    },
    /**
     * @param {*} getSelectRoleNameList 筛选角色名称事件
     * @return {*}
     */
    async getSelectRoleNameList() {
      let roleName = this.filterData.roleName;
      let page = this.paginationTable.page;
      let limit = this.paginationTable.limit;
      let res = await getRoleListData(roleName, limit, page);
      this.roleDataSource = res.code === 0 && res.page.list;
      this.paginationTable.total = res.code === 0 && res.page.totalCount;
      this.paginationTable.limit = res.code === 0 && res.page.pageSize;
      this.paginationTable.current = res.code === 0 && res.page.currPage;
      this.tableLoading = false;
    },

    /**查询角色事件
     * @param {*}
     * @return {*}
     */
    async selectRoleClickBtn() {
      if (this.tableLoading === true) {
        return;
      }
      this.tableLoading = true;
      let roleName = this.filterData.roleName;
      this.isSelectRole = true;
      this.paginationTable.page = '1';
      if (roleName) {
        this.getSelectRoleNameList();
      } else {
        this.getRoleList();
      }
    },
    /** 确认角色会话框事件
     * @param {*}
     * @return {*}
     */
    confirmRoleModalClickBtn() {
      this.$refs.roleModalForm.validate().then(() => {
        this.tableLoading = true;
        let postParams = {
          ...this.roleModalData,
        };

        if (postParams.roleId) {
          updateRoleData(postParams).then(res => {
            if (res.code === 0) {
              this.roleModalData.menuIdList = [];
              this.$message.success('修改成功');
              this.editRoleVisible = false;
              this.getRoleList();
              this.tableLoading = false;
            }
          });
        } else {
          saveRoleData(postParams).then(res => {
            if (res.code === 0) {
              this.roleModalData.menuIdList = [];
              this.$message.success('新增成功');
              this.editRoleVisible = false;
              this.paginationTable.page = '1';
              this.getRoleList();
              this.tableLoading = false;
            }
          });
        }
      });
    },
    /** 取消会话框事件
     * @param {*}
     * @return {*}
     */
    cancelRoleModalClickBtn() {
      this.roleModalData = {};
      this.$refs.roleModalForm.resetFields();

      /** 删除选中角色事件
       * @param {*}
       * @return {*}
       */
    },
    removeRoleClickBtn() {
      this.deleteModalvisible = true;
      if (this.selectedRowKeys.length == 0) {
        this.deleteModalType = '请选择一条记录';
      } else {
        this.deleteModalType = '确定要删除选中的记录？';
      }
    },
    deleteRoleClickBtn() {
      let postParams = [...this.selectedRowKeys];
      if (postParams.length > 0) {
        deleteRoleData(postParams).then(res => {
          this.tableLoading = true;
          if (res.code === 0) {
            this.$message.success('删除成功');
            this.getRoleList();
            this.deleteModalvisible = false;
            this.tableLoading = false;
          }
        });
      } else {
        this.deleteModalvisible = false;
      }
    },
    /** 点击表格行
     * @param {*} record 点击角色表单一行的数据
     * @return {*}
     */
    customClickRow(record) {
      return {
        onClick: event => {
          let indexOfIndex = this.selectedRowKeys.indexOf(record.roleId);
          if (indexOfIndex !== -1) {
            this.selectedRowKeys.splice(indexOfIndex, 1);
          } else {
            this.selectedRowKeys.push(record.roleId);
          }
        },
      };
    },
    /** 选中框发生变化
     * @param {*} selectedRowKeys 已选中的行
     * @return {*}
     */
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = [...selectedRowKeys];
    },
  },
};
</script>

<style lang="less" scoped>
.color85 {
  color: rgba(0, 0, 0, 0.85);
}
.role_system {
  display: flex;
  flex-direction: column;
  .filter_row {
    .ant-input-affix-wrapper {
      padding: 0 10px;
    }
    .ant-col-6 {
      display: flex;
      align-items: center;
      .ant-form-item {
        margin-bottom: 0;
        :deep(.ant-form-item-control) {
          line-height: 30px !important;
          margin-bottom: 5px;
        }
      }
    }
  }
  :deep(.ant-table-body) {
    max-height: calc(100vh - 265px) !important;
  }
}
</style>
<style lang="less">
.set-role-authority-modal {
  .ant-modal-body {
    padding: 0;
  }
  .info-box {
    padding: 12px 20px;
    background: #ffffff;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.13);
    .mr12 {
      margin-right: 12px;
    }
  }
  .authority-box {
    .ant-tabs-bar {
      background: #f5f7fa;
      height: 48px;
      margin-bottom: 12px;
    }
    .ant-tabs-tab {
      font-size: 14px;
      color: #666666;
      &-active {
        color: #2f70f4;
      }
    }

    .ant-tabs-nav {
      margin-left: 10px;
    }
    .authority-list {
      width: 764px;
      height: 516px;
      margin: 0 12px;
      background: #ffffff;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      .menu-list {
        width: 162px;
        height: 100%;
        .ant-menu-item {
          margin-top: 0;
          a::before {
            display: none;
          }
        }
        .ant-menu-item:first-child {
          border-radius: 0.125rem 0 0 0;
        }
        .ant-menu-item-selected {
          background: #197afb;
          a {
            color: #fff;
            font-size: 0.4375rem;
          }
          .anticon.anticon-plus-circle {
            color: #fff;
          }
        }
      }
      .menu-detail-list {
        width: 600px;
        height: 100%;
        position: relative;
        overflow-y: auto;
        .ant-card-head {
          height: 1.125rem;
          min-height: 1.125rem;
          padding: 0.175rem 0.375rem 0.125rem;
          background: #f5f7fa;
          .ant-card-head-title {
            padding: 0;
          }
        }
        .child-card {
          .ant-card-head {
            height: 1.125rem;
            min-height: 1.125rem;
            font-size: 12px;
            padding: 0;
            background: none;
            border-bottom: none;
            .ant-card-head-title {
              padding: 0;
            }

            .child-menu-name::after {
              content: '';
              width: 80%;
              height: 1px;
              position: absolute;
              top: 10px;
              margin-left: 5px;
              background: #eeeeee;
            }
          }
          .ant-card-body {
            padding: 0;
          }
        }
        .checkbox-wrapper {
          width: 100%;
          .toolbox {
            display: none !important;
          }
          .checkbox-item:hover .toolbox {
            display: flex !important;
          }
          .ant-col {
            margin-bottom: 0.6315rem;
          }
          .checkbox-select {
            min-width: 120px;
          }
          .ant-checkbox-wrapper {
            width: 100%;
            display: flex;
            align-items: center;
            .ant-checkbox + span {
              width: 100%;
            }
            .check-label {
              display: inline-block;
              width: 85%;
              .check-label-name {
                display: inline-block;
                width: 80%;
              }
            }
            a {
              display: inline-block;
              width: 100%;
            }
          }
        }
      }
    }
  }
  .buttons {
    .btn {
      min-width: 72px;
      min-height: 30px;
      border-radius: 4px;
    }
  }
}
.add-func-modal {
  z-index: 1002;
  .buttons {
    .btn {
      min-width: 72px;
      min-height: 30px;
      border-radius: 4px;
    }
  }
}
</style>