/*
 * @Author: zhangruifeng
 * @Date: 2021-05-18 14:00:15
 * @LastEditors: Yran
 * @LastEditTime: 2021-08-19 19:02:06
 */
import axios from '@/utils/axios';
const apiUrl = {
    getRoleList: 'sys/role/list',
    saveRole: 'sys/role/save',
    updateRole: 'sys/role/update',
    deleteRole: 'sys/role/delete',
    getRoleData: '/sys/role/getRoleData',
    getRoleDataByRoleId: '/sys/role/getRoleDataByRoleId',
};
export function getRoleListData(roleName, limit, page) {
    return axios.get(apiUrl.getRoleList + `?roleName=${roleName}&limit=${limit}&page=${page}`);
}
export function deleteRoleData(params) {
    return axios.post(apiUrl.deleteRole, params);
}
export function saveRoleData(params) {
    return axios.post(apiUrl.saveRole, params);
}
export function updateRoleData(params) {
    return axios.post(apiUrl.updateRole, params);
}
export function getRoleData() {
    return axios.get(apiUrl.getRoleData);
}
export function getRoleDataByRoleId(roleId) {
    return axios.get(apiUrl.getRoleDataByRoleId + '/' + roleId);
}