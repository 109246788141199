/*
 * @Author: zhangruifeng
 * @Date: 2021-05-18 14:00:37
 * @LastEditors: Yran
 * @LastEditTime: 2021-08-20 11:16:51
 */
import axios from '@/utils/axios';

const apiUrl = {
    // 菜单列表
    getMenu: '/sys/menu/getPage',
    saveMenu: 'sys/menu/save',
    saveMenus: 'sys/menu/saves',
    updateMenu: 'sys/menu/update',
    deleteMenu: 'sys/menu/delete',
};

// 获取菜单列表
export function getMenuList(params) {
    return axios.post(apiUrl.getMenu, params);
}

// 新增菜单
export function saveMenu(param) {
    return axios.post(apiUrl.saveMenu, param);
}

// 新增菜单
export function saveMenus(param) {
    return axios.post(apiUrl.saveMenus, param);
}

// 编辑菜单
export function updateMenu(param) {
    return axios.post(apiUrl.updateMenu, param);
}


// 删除菜单
export function deleteMenu(param) {
    return axios.post(apiUrl.deleteMenu, param);
}